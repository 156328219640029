import styled from "styled-components"
import { media } from "../../../styles/tools"
import { colors, space } from "../../../styles/vars"

export const StyleService = styled.div`
  text-align: center;

  ${media.med`
  max-width: 600px;
  margin: 0 auto;
  display: grid;
    grid-template-columns: 1fr 1fr;
  `}
`
export const StyleIconTitleContainer = styled.div``

export const StyleTitle = styled.h3`
  margin-top: ${space.m};
  margin-bottom: ${space.s};
  color: ${colors.purple.normal};
  font-size: 32px;
`

export const StyleIconContainer = styled.div``

export const StyleDescription = styled.div`
  font-size: 20px;
  ${media.med`
  display: flex;
    align-items: center;
  `};
`
