import React from "react"

const socialGrowthIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="79.33"
    height="95.225"
    viewBox="0 0 79.33 95.225"
  >
    <g id="Group_12" data-name="Group 12" transform="translate(-149 -975)">
      <g
        id="Group_10"
        data-name="Group 10"
        transform="translate(161.281 975.095)"
      >
        <g id="Group_11" data-name="Group 11">
          <g
            id="Path_26"
            data-name="Path 26"
            transform="translate(0 56.176)"
            fill="#fff"
          >
            <path
              d="M 27.54455757141113 35.95388793945312 C 20.77300834655762 35.95388793945312 14.24857711791992 34.98159790039062 9.644217491149902 33.28631973266602 C 7.162807464599609 32.37268829345703 2.999997615814209 30.41185760498047 2.999997615814209 27.54455757141113 C 2.999997615814209 24.22973823547363 3.648537635803223 21.01566886901855 4.927597522735596 17.99162864685059 C 6.163667678833008 15.06923770904541 7.933837413787842 12.4440279006958 10.18893718719482 10.18893814086914 C 12.4440279006958 7.933837890625 15.06923770904541 6.163668155670166 17.99162673950195 4.927597999572754 C 21.01566696166992 3.648538112640381 24.22973823547363 2.999998092651367 27.54455757141113 2.999998092651367 C 30.85937690734863 2.999998092651367 34.07344818115234 3.648538112640381 37.09748840332031 4.927597999572754 C 40.01987838745117 6.163668155670166 42.64508819580078 7.933837890625 44.90017700195312 10.18893814086914 C 47.15527725219727 12.4440279006958 48.92544937133789 15.06923770904541 50.16151809692383 17.99162864685059 C 51.44057846069336 21.01566886901855 52.08911895751953 24.22973823547363 52.08911895751953 27.54455757141113 C 52.08911895751953 30.41185760498047 47.92630767822266 32.37268829345703 45.44489669799805 33.28631973266602 C 40.84053802490234 34.98159790039062 34.31610870361328 35.95388793945312 27.54455757141113 35.95388793945312 Z"
              stroke="none"
            />
            <path
              d="M 27.54455757141113 6.000003814697266 C 15.66485214233398 6.000003814697266 5.999996185302734 15.66484451293945 6.000343322753906 27.55963134765625 C 6.376010894775391 29.04524612426758 13.56959915161133 32.95388412475586 27.54455757141113 32.95388412475586 C 41.51951599121094 32.95388412475586 48.71310424804688 29.04524612426758 49.08911895751953 27.54455184936523 C 49.08911895751953 15.66484451293945 39.42426300048828 6.000003814697266 27.54455757141113 6.000003814697266 M 27.54455757141113 3.814697265625e-06 C 42.75699615478516 3.814697265625e-06 55.08911895751953 12.33212471008301 55.08911895751953 27.54455184936523 C 55.08911895751953 35.15077209472656 41.31683731079102 38.95388031005859 27.54455757141113 38.95388031005859 C 13.77227783203125 38.95388031005859 -3.814697265625e-06 35.15077209472656 -3.814697265625e-06 27.54455184936523 C -3.814697265625e-06 12.33212471008301 12.33211898803711 3.814697265625e-06 27.54455757141113 3.814697265625e-06 Z"
              stroke="none"
              fill="#707070"
            />
          </g>
          <g
            id="Ellipse_2"
            data-name="Ellipse 2"
            transform="translate(7.719 23.905)"
            fill="#fff"
            stroke="#707070"
            strokeWidth="6"
          >
            <circle cx="20" cy="20" r="20" stroke="none" />
            <circle cx="20" cy="20" r="17" fill="none" />
          </g>
        </g>
        <line
          id="Line_7"
          data-name="Line 7"
          y2="23.92"
          transform="translate(54.727)"
          fill="none"
          stroke="#707070"
          strokeWidth="5"
        />
        <line
          id="Line_8"
          data-name="Line 8"
          y2="23.92"
          transform="translate(54.727)"
          fill="none"
          stroke="#707070"
          strokeWidth="5"
        />
        <line
          id="Line_9"
          data-name="Line 9"
          x1="23.92"
          transform="translate(43.129 12.323)"
          fill="none"
          stroke="#707070"
          strokeWidth="5"
        />
      </g>
      <rect
        id="Rectangle_9"
        data-name="Rectangle 9"
        width="79"
        height="95"
        transform="translate(149 975)"
        fill="none"
      />
    </g>
  </svg>
)

export default socialGrowthIcon
