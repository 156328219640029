import React from "react"
import {
  StyleService,
  StyleTitle,
  StyleDescription,
  StyleIconContainer,
  StyleIconTitleContainer,
} from "./styles"

const Service = props => (
  <StyleService>
    <StyleIconTitleContainer>
      <StyleIconContainer>{props.icon}</StyleIconContainer>
      <StyleTitle>{props.title}</StyleTitle>
    </StyleIconTitleContainer>

    <StyleDescription>{props.description}</StyleDescription>
  </StyleService>
)

export default Service
