import React from "react"
import seoIcon from "../../../icons/seo"
import socialGrowthIcon from "../../../icons/socialGrowth"
import SectionHeader from "../../atoms/SectionHeader"
import Service from "../../molecules/Service"
import { StyleServices, StyleHeader, StyleServicesContainer } from "./styles"

const Services = props => (
  <StyleServices>
    <StyleHeader>
      <SectionHeader header={"Services"} />
    </StyleHeader>
    <StyleServicesContainer>
      <Service
        title={"Social Growth"}
        description={
          "Gain followers and generate engagement with our industry leading influencer network of over 3 billion combined followers."
        }
        icon={socialGrowthIcon}
      />
      <Service
        title={"SEO"}
        description={
          "Rise up your search engine results. Full service onsite and offsite custom SEO. Code edits, content, link building, and reports that prove real value to all business."
        }
        icon={seoIcon}
      />
    </StyleServicesContainer>
  </StyleServices>
)

export default Services
