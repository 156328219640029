// Mixins, etc

import { css } from "styled-components";

export const size = {
  small: 600,
  med: 800,
  large: 1000,
  extra: 1240
};

export const media = Object.keys(size).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${size[label] / 16}em) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});
