import React from "react"
import instagramLogo from "../../../icons/instagram"
import logoIcon from "../../../icons/logo"
import ContentContainer from "../../atoms/ContentContainer"
import { StyleContainer, StyleLogoContainer, StyleNavbar } from "./styles"

const Navbar = () => (
  <StyleNavbar>
    <StyleContainer>
      <StyleLogoContainer>{logoIcon}</StyleLogoContainer>
      <div>
        <a
          href="https://www.instagram.com/latom_agency/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {instagramLogo}
        </a>
      </div>
    </StyleContainer>
  </StyleNavbar>
)

export default Navbar
