import * as React from "react"
import Layout from "../components/Layout"
import Services from "../components/organisms/Services"
import styled from "styled-components"
import { space } from "../styles/vars"
import ContentContainer from "../components/atoms/ContentContainer"
import { StaticImage } from "gatsby-plugin-image"
import SectionHeader from "../components/atoms/SectionHeader"
import OurWhy from "../components/organisms/OurWhy"
import Navbar from "../components/organisms/Navbar"
import CTA from "../components/organisms/CTA"
import SEO from "../components/atoms/SEO"

const StyleHappyCustomers = styled.div`
  text-align: center;
`
const StyleWrapper = styled.div`
  padding: 0 ${space.m};
`
const StyleImageWrapper = styled.div`
  @media (min-width: 50em) {
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const StyleGutter = styled.div`
  margin-bottom: 64px;
`
const StyleHeader = styled.div`
  margin-bottom: 50px;
  text-align: center;
  text-transform: uppercase;
`

const IndexPage = () => (
  <Layout>
    <Navbar></Navbar>
    <SEO></SEO>
    <ContentContainer>
      <StyleGutter></StyleGutter>
      <OurWhy></OurWhy>
      <StyleGutter></StyleGutter>

      <StyleWrapper>
        <Services></Services>
      </StyleWrapper>
      <StyleGutter></StyleGutter>
      <StyleWrapper>
        <StyleHappyCustomers>
          <StyleHeader>
            <SectionHeader header={"Happy Clients"} />
          </StyleHeader>
          <StyleImageWrapper>
            <StaticImage
              src="../images/client-2.jpg"
              width={300}
              quality={95}
              alt={"Latom client 2"}
              formats={["AUTO", "WEBP", "AVIF"]}
              style={{ marginBottom: `1.45rem` }}
            />
            <StaticImage
              src="../images/client-3.png"
              width={300}
              alt={"Latom client 3"}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              style={{ marginBottom: `1.45rem` }}
            />
          </StyleImageWrapper>

          <StaticImage
            src="../images/client-1.jpg"
            width={300}
            quality={95}
            alt={"Latom client 1"}
            formats={["AUTO", "WEBP", "AVIF"]}
            style={{ marginBottom: `1.45rem` }}
          />
        </StyleHappyCustomers>
        <StyleGutter></StyleGutter>

        <CTA></CTA>
        <StyleGutter></StyleGutter>
      </StyleWrapper>
    </ContentContainer>
  </Layout>
)

export default IndexPage
