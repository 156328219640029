import React from "react"
import {
  StyleOurWhy,
  StyleHeader,
  StyleHightlight,
  StyleDescription,
  StyleH1,
} from "./styles"

const OurWhy = () => (
  <StyleOurWhy>
    <StyleHeader>
      Grow your <StyleHightlight>IG accounts</StyleHightlight> & rise up your{" "}
      <StyleHightlight>SEO</StyleHightlight>
    </StyleHeader>
    <StyleDescription>
      Through Social Growth and SEO, <StyleH1>Latom</StyleH1> helps brands find
      their voice and share it with the world. We can put you in front of the
      <strong> right audience, in the right way, and at the right time</strong>.
    </StyleDescription>
  </StyleOurWhy>
)

export default OurWhy
