import styled from "styled-components"
import { space } from "../../../styles/vars"

export const StyleServices = styled.div``

export const StyleHeader = styled.div`
  margin-bottom: ${space.l};
  text-align: center;
  text-transform: uppercase;
`
export const StyleServicesContainer = styled.div`
  display: grid;
  grid-row-gap: ${space.l};
`
