import React from "react"
import {
  StyleCTA,
  StyleHeader,
  StyleEmail,
  StyleContactContainer,
  StyleHightlight,
  StyleDescription,
} from "./styles"
const CTA = () => (
  <StyleCTA>
    <StyleHeader>
      Grow your <StyleHightlight>IG accounts</StyleHightlight> & rise up your{" "}
      <StyleHightlight>SEO</StyleHightlight>
    </StyleHeader>
    <StyleDescription>Contact us!</StyleDescription>
    <StyleContactContainer>
      <StyleEmail href="mailto:hello@latom.net" target="_top">
        hello@latom.net
      </StyleEmail>
    </StyleContactContainer>
  </StyleCTA>
)

export default CTA
