import styled from "styled-components"
import { media } from "../../../styles/tools"
import { space } from "../../../styles/vars"

export const StyleOurWhy = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 0 16px;
`

export const StyleHeader = styled.p`
  font-size: 3rem;
  line-height: 4rem;
  text-align: center;
`

export const StyleDescription = styled.div`
  margin-top: ${space.xl};
  margin-bottom: ${space.xl};
  text-align: center;
  font-size: 20px;
`

export const StyleHightlight = styled.span`
  transition: box-shadow 0.2s ease-in-out;
  box-shadow: inset 0 -0.2em 0 0 #620196;
  ${media.large`
  :hover {
    color: white;
    box-shadow: inset 0 -2em 0 0 #620196;
    transition: box-shadow .2s ease-in-out, color .2s ease-in-out;
  }
  
  `}
`
export const StyleH1 = styled.h1`
  display: inline;
  font-weight: 400;
`
