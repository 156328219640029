import React from "react"

const logoIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fill: "#620196",
    }}
    viewBox="0 0 707.43 133.96"
  >
    <defs>
      <style></style>
    </defs>
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path className="cls-1" d="M0,3H34v97h84.3v30.72H0Z" />
        <path
          className="cls-1"
          d="M169.33,33.26H164l-40.64,97.46H86.87L142.3,3h49.43L246,130.72H210Z"
        />
        <path
          className="cls-1"
          d="M231.06,3h133.5V33.49H314.9v97.23H280.72V33.49H231.06Z"
        />
        <path
          className="cls-1"
          d="M497.63,18.94q21.25,18.93,21.25,48t-21.25,48Q476.39,134,447.06,134T396.47,115q-21.24-18.94-21.24-48t21.24-48Q417.73,0,447.06,0T497.63,18.94ZM419.92,39.38Q409.18,49.9,409.18,67t10.74,27.6q10.74,10.52,27.14,10.51,16.15,0,26.9-10.51T484.7,67q0-17.1-10.74-27.6t-26.9-10.51Q430.65,28.87,419.92,39.38Z"
        />
        <path
          className="cls-1"
          d="M539.52,3H583l39.72,85.22h.92L666.55,3h40.88V130.72H673.48V50.35L636.3,130.72H609L572.55,50.35v80.37h-33Z"
        />
      </g>
    </g>
  </svg>
)

export default logoIcon
