import React from "react"

const seoIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="57.451"
    height="58.026"
    viewBox="0 0 57.451 58.026"
  >
    <g id="Group_9" data-name="Group 9" transform="translate(2.121 -0.271)">
      <g
        id="Ellipse_1"
        data-name="Ellipse 1"
        transform="translate(15.33 0.271)"
        fill="#fff"
        stroke="#707070"
        strokeWidth="6"
      >
        <circle cx="20" cy="20" r="20" stroke="none" />
        <circle cx="20" cy="20" r="17" fill="none" />
      </g>
      <line
        id="Line_6"
        data-name="Line 6"
        x1="21.746"
        y2="21.746"
        transform="translate(0 34.431)"
        fill="none"
        stroke="#707070"
        strokeWidth="6"
      />
    </g>
  </svg>
)

export default seoIcon
