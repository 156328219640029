import styled from "styled-components"
import { space } from "../../../styles/vars"

export const StyleNavbar = styled.div`
  padding: ${space.m};
  box-shadow: 0px 1px 5px #999;
`

export const StyleLogoContainer = styled.div`
  width: 120px;
`

export const StyleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  max-width: 1310px;
`
