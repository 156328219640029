import styled from "styled-components"
import { media } from "../../../styles/tools"
import { colors, space } from "../../../styles/vars"

export const StyleCTA = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 0 16px;
`

export const StyleSNSContainer = styled.div`
  display: flex;
  align-items: center;
`

export const StyleEmail = styled.a`
  color: ${colors.purple.normal};
  font-size: 2rem;
`

export const StyleDivider = styled.span`
  width: 2px;
  margin: 0 16px;
  background-color: #707070;
`

export const StyleContactContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const StyleHeader = styled.p`
  font-size: 3rem;
  line-height: 4rem;
  text-align: center;
`

export const StyleDescription = styled.div`
  margin-top: ${space.m};
  margin-bottom: ${space.m};

  text-align: center;
  font-size: 20px;
`
export const StyleHightlight = styled.span`
  transition: box-shadow 0.2s ease-in-out;
  box-shadow: inset 0 -0.2em 0 0 #620196;
  ${media.large`
  :hover {
    color: white;
    box-shadow: inset 0 -2em 0 0 #620196;
    transition: box-shadow .2s ease-in-out, color .2s ease-in-out;
  }
  
  `}
`

export const StyleInstaLink = styled.a`
  display: flex;
  position: relative;
  top: 3px;
`
